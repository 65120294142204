<template>
  <div>
    <v-card flat class="my-6 mx-9 mb-0 rounded-lg">
      <MapPanel :details="details" :show="show" />
    </v-card>
  </div>
</template>

<script>
import MapPanel from "@/components/MapPanel";

export default {
  props: ["details", "show"],
  components: {
    MapPanel
  }
};
</script>

<style lang="scss" scoped></style>
